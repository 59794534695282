import React from "react"
import PropTypes from "prop-types"
function HeroCard({
  icon,
  rootClassName,
  iconClassName,
  children,
  iconDirection,
  slideAnimation,
}) {
  return (
    <div data-sal={slideAnimation && `${slideAnimation}`} className={`flex flex-row ${rootClassName}`}>
      {icon && iconDirection === "left" && (
        <div className={iconClassName ? `${iconClassName}` : "m-2"}>{icon}</div>
      )}
      {children}
      {icon && iconDirection === "right" && (
        <div className={iconClassName ? `${iconClassName}` : "max-w-xl m-2"}>{icon}</div>
      )}
    </div>
  )
}

HeroCard.propTypes = {
  icon: PropTypes.any,
  rootClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  iconDirection: PropTypes.string,
  children: PropTypes.node,
  slideAnimation: PropTypes.string
}

HeroCard.defaultProps = {
  icon: null,
  rootClassName: "",
  iconClassName: "",
  iconDirection: "left",
  children: null,
}

export default HeroCard
