import React from "react"
import { PageProps, Link, graphql } from "gatsby"
import Page from "../components/common/page"
import Card from "../components/Card"

import HeroSection from "../components/HeroSection"
import ContactUsSection from "../components/ContactUsSection"
import HeroCard from "../components/HeroCard"
// Svg imports
import HeroIcon from "../images/svg/about-us/hero-icon.inline.svg"
import HeroIconBg from "../images/svg/why-video-learning/hero-bg.inline.svg"

import CardIcon from "../images/svg/about-us/card-icon.inline.svg"
import CardIcon2 from "../images/svg/about-us/card-icon2.inline.svg"
import CardIconItem1 from "../images/svg/about-us/card-items/card-icon-item-1.inline.svg"
import CardIconItem2 from "../images/svg/about-us/card-items/card-icon-item-2.inline.svg"
import CardIconItem3 from "../images/svg/about-us/card-items/card-icon-item-3.inline.svg"
import CardIconItem4 from "../images/svg/about-us/card-items/card-icon-item-4.inline.svg"
import CardIconItem5 from "../images/svg/about-us/card-items/card-icon-item-5.inline.svg"
import CardIconItem6 from "../images/svg/about-us/card-items/card-icon-item-6.inline.svg"
import CardIconItem7 from "../images/svg/about-us/card-items/card-icon-item-7.inline.svg"

import InfoSectionSoftSkillsIcon from "../images/svg/about-us/info-section/soft-skills-icon.inline.svg"
import InfoSectionHealthAndSafetyIcon from "../images/svg/about-us/info-section/health-and-safety.inline.svg"
import InfoSectionProceduresAndOperationsIcon from "../images/svg/about-us/info-section/procedures-and-operations.inline.svg"
import InfoSectionEmployeeInductionIcon from "../images/svg/about-us/info-section/employee-induction.inline.svg"
import InfoSectionSoftwareSkillsIcon from "../images/svg/about-us/info-section/software-skills.inline.svg"
import InfoSectionTechnicalskillsIcon from "../images/svg/about-us/info-section/technical-skills.inline.svg"
import InfoSectionProductTrainingIcon from "../images/svg/about-us/info-section/product-training-icon.inline.svg"
import Header from "../components/header"
import Footer from "../components/footer"
import { Trans, useTranslation } from "react-i18next"

const AboutUsPage = (props: PageProps) => {
  const {t} = useTranslation();

  return (
    <Page title="About us">
      <Header/>
      <div className="w-full overflow-x-hidden">
      <div className="relative">
          <div className="relative z-20">
          <HeroSection
          title={
            <p className="gothic-bold lg:text-6xl text-4xl text-secondary">
              {t('what_we_do_question')}
            </p>
          }
          icon={<HeroIcon />}
        />
          </div>
          <HeroIconBg className="bg-background w-full absolute -bottom-12 lg:-bottom-12 z-0" />
        </div>

        <div className="lg:mt-12 mt-4 bg-background flex flex-col text-center items-center">
          <div
            className="lg:w-1/2 lg:px-0 px-4 w-full mb-primary text-left"
            data-sal="slide-right"
          >
            <div className="mt-24 lg:text-3xl text-xl w-full lg:leading-snug">
              <p className="gothic-bold text-dark-grey">
                {t('s4g_has_developed')}
                <span className="text-secondary">
                  {" "}
                  {t('structured_services_model')}
                </span>
                <span>
                {t('fullfill_expectations')}
                </span>
                <span className="gothic-bold text-secondary">
                {" "}{t('custom_online_video_academies')}{" "}
                </span>
                {t('at_the_time_you_desire')}
              </p>
            </div>
            <div className="my-6 flex flex-col items-start text-left lg:text-base text-sm">
              <p className="gothic-bold">
              {t('team_consists_of')}
              </p>
              <ul className="list-disc list-inside text-secondary mt-5">
                <li className="text-secondary mt-5">
                  <span className="text-dark-grey">
                  {t('video_production_expertise')}
                  </span>
                </li>
                <li className="text-secondary mt-5">
                  <span className="text-dark-grey">
                  {t('experienced_members')}
                  </span>
                </li>
                <li className="text-secondary mt-5">
                  <span className="text-dark-grey">
                  {t('board_partner_network')}
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="bg-white w-full lg:pb-20 pb-4">
            <div className="lg:my-primary my-4 text-2xl w-full lg:text-5xl lg:leading-normal">
              <p className="text-secondary gothic-bold">{t('video_academies_explanation')}</p>
              <p className="gothic-bold text-dark-grey">{t('video_academies')}</p>
            </div>

            <div
              className={`flex flex-row flex-wrap p-4 justify-center text-left`}
            >
              {cardItems.map((item, i) => (
                <Card
                  rootClassName="p-8 m-2 lg:w-3/12 w-full text-left bg-background rounded-xl"
                  titleClassName="lg:text-3xl text-2xl my-4 text-left"
                  subtitleClassName="mt-2 lg:text-base text-sm"
                  key={i}
                  title={t(item.title)}
                  subtitle={t(item.subtitle)}
                  icon={item.icon}
                  slideAnimation="slide-left"
                ></Card>
              ))}
            </div>
          </div>

          <div className="flex flex-col bg-background items-center lg:py-12 py-4">
            <div className="lg:mx-primary mx-5 mt-8">
              <HeroCard
                iconClassName="order-1 mb-2 lg:mb-0 flex-1 max-h-96"
                iconDirection="right"
                icon={<CardIcon />}
                rootClassName="lg:p-20 bg-white flex-wrap justify-between rounded-xl p-8 mx-4 lg:mx-0"
                slideAnimation="slide-right"
              >
                <div className="lg:w-7/12 w-full lg:order-1 order-2 lg:text-left text-center">
                  <p className="lg:text-5xl text-dark-grey text-2xl gothic-bold lg:mt-0 my-10 lg:leading-normal">
                    {t('why_a_series_of_videos')}{" "}
                    <div className="text-secondary">{t('why_not_a_video')}</div>
                  </p>

                  <p className="mt-6 lg:text-base text-sm">
                    {t('why_a_series_text_1')}
                  </p>
                  <ul className="mt-6 list-disc text-secondary list-inside lg:text-base text-sm">
                    <li className="mt-6 text-dark-grey">
                    {t('why_a_series_text_2')}
                    </li>
                    <li className="mt-6 text-dark-grey">
                    {t('why_a_series_text_3')}
                    </li>
                  </ul>
                </div>
              </HeroCard>
              <HeroCard
                iconClassName="flex-1 max-h-96"
                iconDirection="left"
                icon={<CardIcon2 />}
                rootClassName="mt-5 lg:p-20 bg-white flex-wrap justify-between rounded-xl p-8 mx-4 lg:mx-0"
                slideAnimation="slide-left"
              >
                <div className="lg:w-7/12 w-full lg:text-right text-center">
                  <p className="lg:text-5xl text-2xl text-dark-grey gothic-bold mb-8 lg:mt-0 my-10 lg:leading-normal">
                    {t('difference_between_traditional_video_prod')}{" "}
                    <div className="text-secondary">
                    {t('difference_between_traditional_video_prod_sub')}
                    </div>
                  </p>

                  <p className="mt-2 lg:text-base text-sm">
                  {t('difference_between_traditional_video_prod_desc')}
                  </p>
                </div>
              </HeroCard>
            </div>
          </div>

          <div className="mt-12 flex flex-col">
            <div
              className="lg:w-1/2 w-full px-8 self-center"
              data-sal="slide-right"
            >
              <p className="lg:text-5xl text-2xl gothic-bold lg:leading-normal text-dark-grey">
                {t("what_kind_of_information")}{" "}
                <span className="text-secondary">
                  {t("can_be_translated_to_video")}
                </span>
              </p>
              <p className="mt-5 lg:text-base text-sm text-dark-grey">
                {t("info_translated_to_video_text_1")}
              </p>
              <p className="gothic-bold mt-6 lg:text-base text-sm text-dark-grey">
              {t("info_translated_to_video_text_2")}
              </p>
            </div>
            <div className="lg:w-4/6 w-full my-14 px-8 self-center">
              <div className="grid grid-rows-1 gap-2 md:grid-cols-2 lg:grid-cols-3 lg:md:gap-4">
                <div className="lg:pl-60 lg:animate-beatslow">{ <InfoSectionProductTrainingIcon />}</div>
                <div className="pl-40 lg:pl-52 lg:animate-beatslow lg:pt-10 md:m-auto">{ <InfoSectionSoftwareSkillsIcon />}</div>
                <div className="lg:pl-40 lg:animate-beatslow lg:pt-16 md:m-auto">{ <InfoSectionHealthAndSafetyIcon />}</div>
                <div className="lg:pb-16 lg:animate-beatslow md:m-auto">{ <InfoSectionSoftSkillsIcon />}</div>
                <div className="pl-20 lg:pl-10 lg:pb-10 lg:animate-beatslow md:m-auto">{ <InfoSectionProceduresAndOperationsIcon />}</div>
                <div className="lg:pr-50 lg:pt-16 lg:animate-beatslow md:m-auto">{ < InfoSectionTechnicalskillsIcon />}</div>
                <div className="pl-20 lg:pb-16 lg:pl-52 lg:animate-beatslow md:m-auto">{ <InfoSectionEmployeeInductionIcon />}</div>
              </div>
            </div>
          </div>

          <div
            className={`flex flex-row flex-wrap lg:w-11/12 w-full p-4 justify-center text-left`}
          >
            {cardItems2.map((item, i) => (
              <Card
                rootClassName="p-8 m-2 lg:flex-40% text-left bg-white rounded-xl"
                titleClassName="lg:text-3xl text-2xl my-4 text-left"
                subtitleClassName="mt-2 lg:text-base text-sm"
                key={i}
                title={item.title}
                subtitle={item.subtitle}
                icon={item.icon}
                slideAnimation="flip-up"
              ></Card>
            ))}
          </div>
          <ContactUsSection />
        </div>
      </div>
      <Footer/>
    </Page>
  )
}

const cardItems2 = [
  {
    icon: <CardIconItem1 />,
    title: (
      <p className="gothic-bold text-card">
        <Trans>what_type_of_videos</Trans> <div className="text-dark-grey"><Trans>what_type_of_videos_produce</Trans></div>
      </p>
    ),
    subtitle: (
      <>
        <p>
          <Trans>what_type_videos_text_1</Trans>
        </p>
        <p className="mt-4">
          {" "}
          <Trans>what_type_of_videos_text_2</Trans>
        </p>
      </>
    ),
  },
  {
    icon: <CardIconItem2 />,
    title: (
      <p className="gothic-bold text-card">
        <Trans>how_is_structured</Trans>
        <div className="text-dark-grey"> 
        <Trans>the_lesson_architecture</Trans>
        </div>
       
      </p>
    ),
    subtitle: (
      <p>
        <Trans>how_is_structured_text</Trans>
      </p>
    ),
  },
  {
    icon: <CardIconItem3 />,
    title: (
      <p className="gothic-bold text-card">
        <Trans>where_can_i_use</Trans>
        <div className="text-dark-grey"> <Trans>the_content_you_produce_for_me</Trans> </div>
      </p>
    ),
    subtitle: (
      <>
        <p>
          <Trans>where_can_i_use_text_1</Trans>
        </p>
        <p className="mt-4">
        <Trans>where_can_i_use_text_2</Trans>
        </p>
      </>
    ),
  },
  {
    icon: <CardIconItem4 />,
    title: (
      <p className="gothic-bold text-card">
         <Trans>what_is_the_duration</Trans>
        <div className="text-dark-grey"> <Trans>of_a_video</Trans> </div>
      </p>
    ),
    subtitle: (
      <p>
        <Trans>duration_of_a_video_text</Trans>
      </p>
    ),
  },
]
const cardItems = [
  {
    icon: <CardIconItem5 />,
    title: "custom",
    subtitle:"original_content",
  },
  {
    icon: <CardIconItem6 />,
    title: "online",
    subtitle: "knowledge_availability"
      
  },
  {
    icon: <CardIconItem7 />,
    title: "video_academy",
    subtitle: "knowledge_transmition"
  },
]

export default AboutUsPage
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["about-us", "common"]}, language: {eq: $language}})  {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;