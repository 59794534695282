import PropTypes from "prop-types"
import React from "react"

function Card({
  icon,
  title,
  subtitle,
  rootClassName,
  iconClassName,
  titleClassName,
  subtitleClassName,
  slideAnimation
}) {
  return (
    <div data-sal={slideAnimation && `${slideAnimation}`} className={`${rootClassName} flex flex-col`}>
      {icon && (
        <div className={iconClassName ? `${iconClassName}` : "m-2"}>{icon}</div>
      )}
      {title && (
        <div
          className={titleClassName ? `${titleClassName}` : "text-secondary"}
        >
          <p className="gothic-bold text-dark-grey">{title}</p>
        </div>
      )}
      {subtitle && (
        <div className={subtitleClassName ? `${subtitleClassName}` : ""}>
          {subtitle}
        </div>
      )}
    </div>
  )
}

Card.propTypes = {
  icon: PropTypes.any,
  rootClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
  title: PropTypes.any,
  subtitle: PropTypes.any,
}

Card.defaultProps = {
  icon: null,
  rootClassName: "",
  titleClassName: "",
  subtitleClassName: "",
  iconClassName: "",
  title: "",
  subtext: "",
}

export default Card
