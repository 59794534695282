import React from "react"

const HeroSection = ({ icon, title }) => {
  return (
      <div className="sm:flex flex-col lg:flex-row lg:justify-between justify-center flex-wrap items-center">
        <div data-sal="slide-right" className="w-full p-10 md:p-30 lg:w-1/2 lg:p-40">{title}</div>
        <div data-sal="slide-left" className="w-full lg:w-1/2">{icon}</div>
    </div>

  )
}
export default HeroSection